import { Component, Injectable, TemplateRef, ViewChild } from '@angular/core';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';

@Injectable({
  providedIn: 'root'
})
export class DrawerService {
  constructor(
    private drawerService: NzDrawerService
  ) { }

  open(component:any, title:string, height:string | number = '300px', params?:any){
    const drawer = this.drawerService.create({
      nzContent: component,
      nzTitle: title,
      nzContentParams: params,
      nzPlacement: 'bottom',
      nzHeight: height,
    });
    drawer.afterClose.subscribe({
      next: resp => {
        console.log(resp);
      }
    })
  }
}
